import { render, staticRenderFns } from "./TheFooter.vue?vue&type=template&id=030b3928&scoped=true&"
import script from "./TheFooter.vue?vue&type=script&lang=js&"
export * from "./TheFooter.vue?vue&type=script&lang=js&"
import style0 from "./TheFooter.vue?vue&type=style&index=0&id=030b3928&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "030b3928",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterLinkListWithHeading: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/footer/FooterLinkListWithHeading.vue').default,BaseHeading: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseHeading.vue').default,BaseIcon: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseIcon.vue').default,FooterCopyrights: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/footer/FooterCopyrights.vue').default,BaseContainer: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseContainer.vue').default,FooterScrollToTop: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/footer/FooterScrollToTop.vue').default})
